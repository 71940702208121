<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="344"
    height="79"
    viewBox="0 0 344 79"
  >
    <!-- <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_1798"
          data-name="Rectangle 1798"
          width="26"
          height="26"
          transform="translate(33 218)"
          :fill="value == 1 ? '#80c141' : '#f47306'"
          stroke="#707070"
          stroke-width="1"
        />
      </clipPath>
      <clipPath id="clip-path-2">
        <rect
          id="Rectangle_1803"
          data-name="Rectangle 1803"
          width="26"
          height="26"
          transform="translate(127 218)"
          fill="#f47306"
          stroke="#707070"
          stroke-width="1"
          opacity="0.5"
        />
      </clipPath>
      <clipPath id="clip-path-3">
        <rect
          id="Rectangle_1804"
          data-name="Rectangle 1804"
          width="26"
          height="26"
          transform="translate(222 218)"
          fill="#f47306"
          stroke="#707070"
          stroke-width="1"
          opacity="0.5"
        />
      </clipPath>
      <clipPath id="clip-path-4">
        <rect
          id="Rectangle_1805"
          data-name="Rectangle 1805"
          width="26"
          height="26"
          transform="translate(316 218)"
          fill="#f47306"
          stroke="#707070"
          stroke-width="1"
          opacity="0.5"
        />
      </clipPath>
    </defs> -->
    <text
      id="ยื่นเอกสาร"
      transform="translate(30 75)"
      :fill="value == 1 ? '#000' : '#ccc'"
      font-size="10"
      font-family="Kanit-Regular, Kanit"
    >
      <tspan x="-21.25" y="0">ยื่นเอกสาร</tspan>
    </text>
    <text
      id="ตรวจสอบ"
      transform="translate(124 75)"
      :fill="value == 2 ? '#000' : '#ccc'"
      font-size="10"
      font-family="Kanit-Regular, Kanit"
    >
      <tspan x="-20.105" y="0">ตรวจสอบ</tspan>
    </text>
    <text
      id="ดำเนินการ"
      transform="translate(219 75)"
      :fill="value == 3 ? '#000' : '#ccc'"
      font-size="10"
      font-family="Kanit-Regular, Kanit"
    >
      <tspan x="-20.785" y="0">ดำเนินการ</tspan>
    </text>
    <text
      id="ผลการประเมิน"
      transform="translate(315 75)"
      :fill="value == 4 || value == 5 ? '#000' : '#ccc'"
      font-size="10"
      font-family="Kanit-Regular, Kanit"
    >
      <tspan x="-28.805" y="0">ผลการประเมิน</tspan>
    </text>
    <g id="success" :opacity="value == 1 ? '1' : '0.2'">
      <circle
        id="Ellipse_1"
        data-name="Ellipse 1"
        cx="30"
        cy="30"
        r="30"
        transform="translate(0 0)"
        :fill="value == 1 ? '#80c141' : '#f47306'"
      />
    </g>
    <g
      id="success-2"
      data-name="success"
      transform="translate(93.999)"
      :opacity="value == 2 ? '1' : '0.2'"
    >
      <circle
        id="Ellipse_1-2"
        data-name="Ellipse 1"
        cx="30"
        cy="30"
        r="30"
        transform="translate(0 0)"
        :fill="value == 2 ? '#80c141' : '#f47306'"
      />
    </g>
    <g
      id="success-3"
      data-name="success"
      transform="translate(189.001)"
      :opacity="value == 3 ? '1' : '0.2'"
    >
      <circle
        id="Ellipse_1-3"
        data-name="Ellipse 1"
        cx="30"
        cy="30"
        r="30"
        transform="translate(0 0)"
        :fill="value == 3 ? '#80c141' : '#f47306'"
      />
    </g>
    <g
      id="success-4"
      data-name="success"
      transform="translate(283)"
      :opacity="value == 4 || value == 5 ? '1' : '0.2'"
    >
      <circle
        id="Ellipse_1-4"
        data-name="Ellipse 1"
        cx="30"
        cy="30"
        r="30"
        transform="translate(0 0)"
        :fill="value == 4 || value == 5 ? '#80c141' : '#f47306'"
      />
    </g>
    <g
      id="success-5"
      data-name="success"
      transform="translate(6 6)"
      :opacity="value == 1 ? '1' : '0.7'"
    >
      <circle
        id="Ellipse_1-5"
        data-name="Ellipse 1"
        cx="24"
        cy="24"
        r="24"
        fill="#fff"
      />
    </g>
    <g
      id="success-6"
      data-name="success"
      transform="translate(100 6)"
      :opacity="value == 2 ? '1' : '0.7'"
    >
      <circle
        id="Ellipse_1-6"
        data-name="Ellipse 1"
        cx="24"
        cy="24"
        r="24"
        fill="#fff"
      />
    </g>
    <g
      id="success-7"
      data-name="success"
      transform="translate(195 6)"
      :opacity="value == 3 ? '1' : '0.7'"
    >
      <circle
        id="Ellipse_1-7"
        data-name="Ellipse 1"
        cx="24"
        cy="24"
        r="24"
        fill="#fff"
      />
    </g>
    <g
      id="success-8"
      data-name="success"
      transform="translate(289 6)"
      :opacity="value == 4 || value == 5 ? '1' : '0.7'"
    >
      <circle
        id="Ellipse_1-8"
        data-name="Ellipse 1"
        cx="24"
        cy="24"
        r="24"
        fill="#fff"
      />
    </g>
    <circle
      id="Ellipse_399"
      data-name="Ellipse 399"
      cx="2"
      cy="2"
      r="2"
      transform="translate(61 29)"
      fill="#ccc"
    />
    <circle
      id="Ellipse_408"
      data-name="Ellipse 408"
      cx="2"
      cy="2"
      r="2"
      transform="translate(156 29)"
      fill="#ccc"
    />
    <circle
      id="Ellipse_413"
      data-name="Ellipse 413"
      cx="2"
      cy="2"
      r="2"
      transform="translate(250 29)"
      fill="#ccc"
    />
    <circle
      id="Ellipse_400"
      data-name="Ellipse 400"
      cx="2"
      cy="2"
      r="2"
      transform="translate(68 29)"
      fill="#ccc"
    />
    <circle
      id="Ellipse_407"
      data-name="Ellipse 407"
      cx="2"
      cy="2"
      r="2"
      transform="translate(163 29)"
      fill="#ccc"
    />
    <circle
      id="Ellipse_412"
      data-name="Ellipse 412"
      cx="2"
      cy="2"
      r="2"
      transform="translate(257 29)"
      fill="#ccc"
    />
    <circle
      id="Ellipse_401"
      data-name="Ellipse 401"
      cx="2"
      cy="2"
      r="2"
      transform="translate(75 29)"
      fill="#ccc"
    />
    <circle
      id="Ellipse_406"
      data-name="Ellipse 406"
      cx="2"
      cy="2"
      r="2"
      transform="translate(170 29)"
      fill="#ccc"
    />
    <circle
      id="Ellipse_411"
      data-name="Ellipse 411"
      cx="2"
      cy="2"
      r="2"
      transform="translate(264 29)"
      fill="#ccc"
    />
    <circle
      id="Ellipse_402"
      data-name="Ellipse 402"
      cx="2"
      cy="2"
      r="2"
      transform="translate(82 29)"
      fill="#ccc"
    />
    <circle
      id="Ellipse_405"
      data-name="Ellipse 405"
      cx="2"
      cy="2"
      r="2"
      transform="translate(177 29)"
      fill="#ccc"
    />
    <circle
      id="Ellipse_410"
      data-name="Ellipse 410"
      cx="2"
      cy="2"
      r="2"
      transform="translate(271 29)"
      fill="#ccc"
    />
    <circle
      id="Ellipse_403"
      data-name="Ellipse 403"
      cx="2"
      cy="2"
      r="2"
      transform="translate(89 29)"
      fill="#ccc"
    />
    <circle
      id="Ellipse_404"
      data-name="Ellipse 404"
      cx="2"
      cy="2"
      r="2"
      transform="translate(184 29)"
      fill="#ccc"
    />
    <circle
      id="Ellipse_409"
      data-name="Ellipse 409"
      cx="2"
      cy="2"
      r="2"
      transform="translate(278 29)"
      fill="#ccc"
    />
    <g
      id="Mask_Group_9"
      data-name="Mask Group 9"
      transform="translate(-16 -201)"
      clip-path="url(#clip-path)"
      :opacity="value == 1 ? '1' : '0.2'"
    >
      <g
        id="_004-document"
        data-name="004-document"
        transform="translate(33 218)"
      >
        <g id="Group_1799" data-name="Group 1799">
          <g id="Group_1798" data-name="Group 1798">
            <path
              id="Path_8930"
              data-name="Path 8930"
              d="M23.327,5.111,18.452.236A.813.813,0,0,0,17.875,0h-13A2.437,2.437,0,0,0,2.437,2.437V23.562A2.437,2.437,0,0,0,4.875,26h16.25a2.437,2.437,0,0,0,2.438-2.437V5.687A.812.812,0,0,0,23.327,5.111ZM21.937,23.562a.812.812,0,0,1-.812.812H4.875a.812.812,0,0,1-.812-.812V2.437a.812.812,0,0,1,.813-.812H17.062v3.25A1.625,1.625,0,0,0,18.687,6.5h3.25Z"
              :fill="value == 1 ? '#80c141' : '#f47306'"
            />
            <path
              id="Path_8931"
              data-name="Path 8931"
              d="M8.937,8.937a2.437,2.437,0,1,0,2.437,2.437A2.437,2.437,0,0,0,8.937,8.937Zm0,3.25a.812.812,0,1,1,.813-.812A.812.812,0,0,1,8.937,12.187Z"
              :fill="value == 1 ? '#80c141' : '#f47306'"
            />
            <path
              id="Path_8932"
              data-name="Path 8932"
              d="M8.937,17.062A2.437,2.437,0,1,0,11.375,19.5,2.437,2.437,0,0,0,8.937,17.062Zm0,3.25A.812.812,0,1,1,9.75,19.5.812.812,0,0,1,8.937,20.312Z"
              :fill="value == 1 ? '#80c141' : '#f47306'"
            />
            <rect
              id="Rectangle_1799"
              data-name="Rectangle 1799"
              width="6.5"
              height="1.625"
              transform="translate(13 8.937)"
              :fill="value == 1 ? '#80c141' : '#f47306'"
            />
            <rect
              id="Rectangle_1800"
              data-name="Rectangle 1800"
              width="3.25"
              height="1.625"
              transform="translate(13 12.187)"
              :fill="value == 1 ? '#80c141' : '#f47306'"
            />
            <rect
              id="Rectangle_1801"
              data-name="Rectangle 1801"
              width="6.5"
              height="1.625"
              transform="translate(13 17.062)"
              :fill="value == 1 ? '#80c141' : '#f47306'"
            />
            <rect
              id="Rectangle_1802"
              data-name="Rectangle 1802"
              width="3.25"
              height="1.625"
              transform="translate(13 20.312)"
              :fill="value == 1 ? '#80c141' : '#f47306'"
            />
          </g>
        </g>
      </g>
    </g>
    <g
      id="Mask_Group_10"
      data-name="Mask Group 10"
      transform="translate(-16 -201)"
      :opacity="value == 2 ? '1' : '0.2'"
      clip-path="url(#clip-path-2)"
    >
      <g id="_002-loupe" data-name="002-loupe" transform="translate(127 218)">
        <g id="Group_1800" data-name="Group 1800">
          <path
            id="Path_8933"
            data-name="Path 8933"
            d="M25.076,20.489,20.8,16.209a11.805,11.805,0,0,0,1.3-5.187A11.022,11.022,0,1,0,11.071,22.045a11.805,11.805,0,0,0,5.187-1.3l4.279,4.279a3.134,3.134,0,0,0,4.539,0A3.379,3.379,0,0,0,25.076,20.489Zm-14-1.037A8.429,8.429,0,1,1,19.5,11.022,8.412,8.412,0,0,1,11.071,19.451Z"
            :fill="value == 2 ? '#80c141' : '#f47306'"
          />
        </g>
      </g>
    </g>
    <g
      id="Mask_Group_11"
      data-name="Mask Group 11"
      transform="translate(-16 -201)"
      :opacity="value == 3 ? '1' : '0.2'"
      clip-path="url(#clip-path-3)"
    >
      <g
        id="_001-hourglass"
        data-name="001-hourglass"
        transform="translate(222 218)"
      >
        <g id="Group_1801" data-name="Group 1801">
          <path
            id="Path_8934"
            data-name="Path 8934"
            d="M17.1,10.3a11.151,11.151,0,0,0,4.267-8.775H22.9V0H3.1V1.523H4.637A11.151,11.151,0,0,0,8.9,10.3c1.156,1.054,1.811,1.7,1.811,2.7s-.654,1.647-1.811,2.7a11.151,11.151,0,0,0-4.267,8.775H3.1V26H22.9V24.477H21.363A11.151,11.151,0,0,0,17.1,15.7c-1.156-1.054-1.811-1.7-1.811-2.7S15.939,11.353,17.1,10.3ZM12.238,18.71a3.851,3.851,0,0,0-.695.213L6.878,20.853a10.393,10.393,0,0,1,3.053-4.026c1.187-1.082,2.308-2.1,2.308-3.827Zm3.831-1.883a10.394,10.394,0,0,1,3.053,4.026l-4.666-1.931a3.852,3.852,0,0,0-.695-.213V13C13.762,14.723,14.883,15.745,16.069,16.827ZM6.851,5.078a11.287,11.287,0,0,1-.689-3.555H19.838a11.285,11.285,0,0,1-.689,3.555Z"
            :fill="value == 3 ? '#80c141' : '#f47306'"
          />
        </g>
      </g>
    </g>
    <g
      v-if="value != 4 || value != 5"
      id="Mask_Group_12"
      data-name="Mask Group 12"
      transform="translate(-16 -201)"
      :opacity="value == 4 ? '1' : '0.2'"
      clip-path="url(#clip-path-4)"
    >
      <g
        id="_003-approved-signal"
        data-name="003-approved-signal"
        transform="translate(316 218)"
      >
        <path
          id="Path_8935"
          data-name="Path 8935"
          d="M24.258,6.5,21.241,4.758,19.5,1.742H16.016L13,0,9.983,1.742H6.5L4.758,4.759,1.742,6.5V9.983L0,13l1.742,3.017V19.5l3.017,1.742L6.5,24.258H9.983L13,26l3.017-1.742H19.5l1.742-3.017L24.258,19.5V16.016L26,13,24.258,9.983V6.5ZM11.826,18.321a1.265,1.265,0,0,1-1.759,0l-3.8-3.8a1.22,1.22,0,0,1,0-1.725l1.006-1.006a1.22,1.22,0,0,1,1.725,0l1.95,1.95L17,7.681a1.22,1.22,0,0,1,1.725,0l1.006,1.006a1.22,1.22,0,0,1,0,1.725Z"
          :fill="value == 4 ? '#80c141' : '#f47306'"
        />
      </g>
    </g>
    <g
      v-if="value == 5"
      id="_002-time"
      data-name="002-time"
      transform="translate(300 16)"
    >
      <g id="Group_1802" data-name="Group 1802">
        <path
          id="Path_8936"
          data-name="Path 8936"
          d="M13,0A13,13,0,1,0,26,13,13,13,0,0,0,13,0Zm.929,13a.929.929,0,0,1-.929.929H5.571a.929.929,0,1,1,0-1.857h6.5v-6.5a.929.929,0,1,1,1.857,0Z"
          fill="#80c141"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
      type: [Number]
    }
  }
};
</script>

<style></style>
